import React from 'react';
import { NavLink } from 'react-router-dom';

const services = [
    {
        count: '01',
        flaticon: 'flaticon-oil',
        title1: 'Perfect Management System',
        title2: '',
        description: 'Our manufacturers use ERP management system to assure each batch quality stable and deliver in time. Meanwhile, CRM(Customer Relationship Management) system  assure each customer\'s order and every item could be correctly recorded and fulfilled.'
    },
    {
        count: '02',
        flaticon: 'flaticon-conveyor',
        title1: 'Product Development',
        title2: '',
        description: 'Based on professional technical team, WELLCON have the ability to develop kinds of projects/products under client\’s request.'
    },
    {
        count: '03',
        flaticon: 'flaticon-robotic-arm',
        title1: 'Strictly Quality Control System',
        title2: '',
        description: 'We strictly fulfill Quality Control System according ISO9001 to keep high quality and stable quality products.'
    },
    {
        count: '04',
        flaticon: 'flaticon-industry',
        title1: 'Big Production Ability',
        title2: '',
        description: 'We are with big production ability to ensure big quantities delivery in time.'
    },
    {
        count: '05',
        flaticon: 'flaticon-scythe',
        title1: 'Approach Customers Regularly',
        title2: '',
        description: 'WELLCON will approach customers for collecting customer\'s requirements, feedback, improve advice and market info. etc., in order to adjust WELLCON to offer better service.'
    },
    {
        count: '06',
        flaticon: 'flaticon-helmet',
        title1: 'Fastest Response',
        title2: '',
        description: 'We assure the fastest response available for any clients in any time.'
    },
    {
        count: '07',
        flaticon: 'flaticon-helmet',
        title1: 'RoHS2/Reach Compliant',
        title2: '',
        description: 'All products from WELLCON comply with RoHS2(2011/65/EU)Products from WELLCON can comply with Reach under Customer\'s request.'
    },
    {
        count: '08',
        flaticon: 'flaticon-helmet',
        title1: 'OEM and ODM welcome',
        title2: '',
        description: 'Based on the advantages, after 5 years’ exploring, our products have been exporting to Europe and get high reputation. Furthermore, WELLCON is confidence to develop more marked across the world in the coming years.'
    },
]

var bnr1 = require('./../../images/background/bg-7.jpg');

class Services13 extends React.Component {
    render() {
        return (
            <>
                <div className="section-full p-t80 p-b40 bg-no-repeat bg-bottom-right bg-cover" style={{ backgroundImage: "url(" + bnr1.default + ")" }}>
                    <div className="container">
                        <div className="section-content">
                            {/* TITLE START*/}
                            <div className="section-head center wt-small-separator-outer text-center">
                                <div className="wt-small-separator site-text-primary">
                                    <div className="sep-leaf-left" />
                                    <div>WELLCON The Best Trade Services</div>
                                    <div className="sep-leaf-right" />
                                </div>
                                <h2>High Performance Services to Support Our Clients' Needs</h2>
                            </div>
                            {/* TITLE END*/}
                            <div className="row d-flex justify-content-center">
                                {services.map((item, index) => (
                                    // TODO：content 内部 item 高度不对齐。
                                    <div key={index} className="col-lg-4 col-md-6 col-sm-12 m-b50">
                                        <div className="service-border-box">
                                            <div className="wt-box service-box-1 bg-white">
                                                <div className="service-box-title title-style-2 site-text-secondry">
                                                    <span className="s-title-one">{item.title1}</span>
                                                    <span className="s-title-two">{item.title2}</span>
                                                </div>
                                                <div className="service-box-content">
                                                    <p>{item.description}</p>
                                                    {/* <NavLink to={"/services-mechanical"} className="site-button-link">Read More</NavLink> */}
                                                </div>
                                                <div className="wt-icon-box-wraper">
                                                    <div className="wt-icon-box-md site-bg-primary">
                                                        <span className="icon-cell text-white"><i className={item.flaticon} /></span>
                                                    </div>
                                                    <div className="wt-icon-number"><span>{item.count}</span></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default Services13;