import React from 'react';
import { NavLink } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const services = [
    {
        count: '01',
        flaticon: 'flaticon-oil',
        title1: 'Technical support',
        title2: '',
        description: 'Our technical experts are available to answer any questions and provide guidance on the use and maintenance of our products.'
    },
    {
        count: '02',
        flaticon: 'flaticon-conveyor',
        title1: 'Customisation',
        title2: '',
        description: 'We offer custom solutions to meet the unique needs, including modifications to existing products or the development of new products.'
    },
    {
        count: '03',
        flaticon: 'flaticon-robotic-arm',
        title1: 'Training',
        title2: '',
        description: 'We offer training programs for our clients to help them better understand and use our products effectively to achieve their goals.'
    },
    {
        count: '04',
        flaticon: 'flaticon-industry',
        title1: 'Maintenance',
        title2: '',
        description: 'We offer repair and maintenance services for our products to ensure that our products operate at peak performance.'
    },
    {
        count: '05',
        flaticon: 'flaticon-scythe',
        title1: 'Calibration',
        title2: '',
        description: 'We provide calibration services for our instruments to ensure they are operating accurately and within specified tolerances.'
    },
    {
        count: '06',
        flaticon: 'flaticon-helmet',
        title1: 'OEM',
        title2: '',
        description: 'We offer OEM services for our standard or customised product lines to meet the needs of your own manufacturing processes.'
    },
]

var bnr1 = require('./../../images/background/bg-7.jpg');

class Services1 extends React.Component {
    render() {

        const options = {
            loop:true,
            autoplay:true,
            nav:true,
            dots: false,	
            margin:10,
            navText: ['<i class="fa fa-long-arrow-left"></i>', '<i class="fa fa-long-arrow-right"></i>'],
            responsive:{
                0:{
                    items:1,
                },
                640:{
                    items:1,
                },			
                767:{
                    items:2,
                 },				
                991:{
                    items:2,
                },
                1366:{
                    items:2,
                },			
                1400:{
                    items:3
                }		
            }
        };
        
        return (
            <>
                <div className="section-full p-t80 p-b70 overlay-wraper bg-no-repeat bg-bottom-left bg-cover services-main-section" style={{ backgroundImage: "url(" + bnr1.default + ")" }}>
                    <div className="overlay-main site-bg-secondry opacity-08" />
                    <div className="section-content services-section-content">
                        <div className="row">
                            <div className="col-xl-6 col-lg-12 col-md-12">
                                <div className="services-section-content-left">
                                    {/* TITLE START*/}
                                    <div className="left wt-small-separator-outer text-white">
                                        <div className="wt-small-separator site-text-primary">
                                            <div className="sep-leaf-left" />
                                            <div>The Best Industry services</div>
                                            {/* <div className="sep-leaf-right" /> */}
                                        </div>
                                        <h2>High Performance Services to Support Our Clients' Needs</h2>
                                        <p>At Lambda Scientific, we offer a wide range of services to meet the needs of our clients. Our team of experienced engineers is dedicated to understanding our clients' needs and providing appropriate solutions to help them achieve their goals. With a focus on innovation and customer satisfaction, we strive to be a reliable partner in the industry.</p>
              <NavLink to="/contactus" className="site-button site-btn-effect">Contact Us</NavLink>
                                    </div>
                                    {/* TITLE END*/}
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-12 col-md-12">
                                <div className="services-section-content-right">
                                    <OwlCarousel className="owl-carousel services-slider owl-btn-vertical-center" {...options}>

                                        {services.map((item, index) => (
                                            <div key={index} className="item">
                                            <div className="wt-box service-box-1 bg-white">
                                                <div className="service-box-title title-style-2 site-text-secondry">
                                                    <span className="s-title-one">{item.title1}</span>
                                                    <span className="s-title-two">{item.title2}</span>
                                                </div>
                                                <div className="service-box-content">
                                                    <p>{item.description}</p>
                                                    <NavLink to="/about-1" className="site-button-link">Read More</NavLink>
                                                </div>
                                                <div className="wt-icon-box-wraper">
                                                    <div className="wt-icon-box-md site-bg-primary">
                                                        <span className="icon-cell text-white"><i className={item.flaticon} /></span>
                                                    </div>
                                                    <div className="wt-icon-number"><span>{item.count}</span></div>
                                                </div>
                                            </div>
                                            </div>
                                        ))}
                                    </OwlCarousel>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default Services1;