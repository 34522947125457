import React from 'react';
import Header2 from './../Parts/Header2';
import Footer from './../Parts/Footer';
import Slider11 from './../Segments/Slider11';
import About8 from './../Segments/About8';
import SelfIntro3 from './../Segments/SelfIntro3';
import Services1 from './../Segments/Services1';
import Services4 from './../Segments/Services4';
import Team10 from './../Segments/Team10';
import GetInTouch1 from './../Segments/GetInTouch1';
import ClientsLogo10 from './../Segments/ClientsLogo10';
import Projects10 from './../Segments/Projects10';
import Testimonials9 from './../Segments/Testimonials9';
import Blog12 from './../Segments/Blog12';

class Home11 extends React.Component {
    render() {
        return (
            <>
                <Header2 />
                    <div className="page-content">
                        <Slider11 />
                        <Blog12 />
                        <Services4 />
                        {/* <About8 />
                        <SelfIntro3 /> */}
                        <Services1 />
                        {/* <Team10 />
                        <GetInTouch1 /> */}
                        
                        {/* <Projects10 /> */}
                        {/* <ClientsLogo10 /> */}
                        {/* <Testimonials9 /> */}
                        
                    </div>
                <Footer />
            </>
        );
    };
};

export default Home11;