import React from 'react';
import { NavLink } from 'react-router-dom';

const services = [
    {
        image: require('./../../images/services/1.jpg'),
        title: 'Pharmacology and Biotechnology',
        description: 'The analysis of drugs, biomolecules, and other biological compounds.'
    },
    {
        image: require('./../../images/services/2.jpg'),
        title: 'Physics Education and Research',
        description: 'Experiments in optics, fiber optics, and other areas of physics.'
    },
    {
        image: require('./../../images/services/3.jpg'),
        title: 'Material Science and Research',
        description: 'Measuring the optical properties of thin-films and other materials.'
    },
    {
        image: require('./../../images/services/4.jpg'),
        title: 'Chemistry',
        description: 'Analyzing the composition and structure of compounds and materials.'
    },
    {
        image: require('./../../images/services/5.jpg'),
        title: 'Environmental Science',
        description: 'The detection of pollutants and contaminants in the environment.'
    },
    {
        image: require('./../../images/services/6.jpg'),
        title: 'Food Science and Agriculture',
        description: 'Quality control and analysis of food and agricultural products.'
    }
]

class Services15 extends React.Component {
    render() {
        return (
            <>
                <div className="section-full p-t80 p-b40">
                    <div className="container">
                        <div className="section-content">
                            <div className="wt-separator-two-part">
                                <div className="row wt-separator-two-part-row">
                                    <div className="col-lg-8 col-md-7 wt-separator-two-part-left">
                                        {/* TITLE START*/}
                                        <div className="section-head left wt-small-separator-outer">
                                            <div className="wt-small-separator site-text-primary">
                                                <div className="sep-leaf-left" />
                                                <div>with Lambda Scientific's cutting-edge instruments</div>
                                                {/* <div className="sep-leaf-right" /> */}
                                            </div>
                                            <h2>Discover new insights and applications</h2>
                                        </div>
                                        {/* TITLE END*/}
                                    </div>
                                    <div className="col-lg-4 col-md-5 wt-separator-two-part-right text-right">
                                        <NavLink to={"/about-1"} className="site-button site-btn-effect">More Detail</NavLink>
                                    </div>
                                </div>
                            </div>
                            <div className="row d-flex justify-content-center">
                                {services.map((item, index) => (
                                    <div key={index} className="col-lg-4 col-md-6 col-sm-12 m-b50">
                                        <div className="service-border-box service-image-box">
                                            <div className="wt-box service-box-1 bg-white">
                                                <div className="service-box-1-media m-b20">
                                                    <img src={item.image.default} alt="" />
                                                </div>
                                                <div className="service-box-title title-style-2 site-text-secondry">
                                                    <span className="s-title-one">{item.title}</span>
                                                    <span className="s-title-two" />
                                                </div>
                                                <div className="service-box-content">
                                                    <p>{item.description}</p>
                                                    <NavLink to={"/services-mechanical"} className="site-button-link">Read More</NavLink>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default Services15;