import React from 'react';
import Header2 from './../Parts/Header2';
import Footer from './../Parts/Footer';
import Slider4 from './../Segments/Slider4';
import Products1 from './../Segments/Products1';
import WhatWeDo2 from './../Segments/WhatWeDo2';
import About1 from './../Segments/About1';
import Achievements1 from './../Segments/Achievements1';
import Services1 from './../Segments/Services1';
import Services13 from './../Segments/Services13';
import Services15 from './../Segments/Services15';
import Statistics4 from './../Segments/Statistics4';
import Video2 from './../Segments/Video2';
import Testimonials1 from './../Segments/Testimonials1';
import Team1 from './../Segments/Team1';
import Blog10 from './../Segments/Blog10';
import Projects4 from './../Segments/Projects4';
import ClientsLogo1 from './../Segments/ClientsLogo1';

class Home4 extends React.Component {
    render() {
        return (
            <>
                <Header2 />
                    <div className="page-content">
                        <Slider4 />
                        <About1 />
                        {/* <Achievements1 /> */}
                        {/* <About1 /> */}
                        <Services13 />
                        
                        {/* <Services13 /> */}
                        
                        <Services15 />
                        {/* <Statistics4 /> */}
                        <Video2 />
                        {/* <Testimonials1 /> */}
                        {/* <Team1 /> */}
                        
                        {/* <Projects4 /> */}
                        {/* <ClientsLogo1 /> */}
                    </div>
                <Footer />
            </>
        );
    };
};

export default Home4;