import React from 'react';
import { NavLink } from 'react-router-dom';

class About1 extends React.Component {
    render() {
        return (
            <>
                <div className="section-full welcome-section-outer" id="about">
                    <div className="welcome-section-top bg-white p-tb80">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6 col-md-12">
                                    <div className="img-colarge">
                                        <div className="colarge-1"><img src={require('./../../images/colarge/fac.jpg').default} alt="" className="slide-righ" /></div>
                                        <div className="since-year-outer"><div className="since-year"><span>Since</span><strong>2017</strong></div></div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-12">
                                    <div className="welcom-to-section">
                                        {/* TITLE START*/}
                                        <div className="left wt-small-separator-outer">
                                            <div className="wt-small-separator site-text-primary">
                                                <div className="sep-leaf-left" />
                                                <div>Welcome to WELLCON</div>
                                                <div className="sep-leaf-right" />
                                            </div>
                                        </div>
                                        <h2>We Are Here To Develop And Trade Eletric Components</h2>
                                        {/* TITLE END*/}
                                        <ul className="site-list-style-one">
                                            <li>Perfect Management System</li>
                                            <li>Product Development</li>
                                            <li>Strictly Quality Control System</li>
                                            <li>Big Production Ability</li>
                                            <li>Approach Customers Regularly</li>
                                            <li>Fastest Response</li>
                                            <li>RoHS2/Reach Compliant</li>
                                            <li>OEM and ODM welcome</li>
                                        </ul>
                                        <p>WELLCON is a trading company in Australia, which owns 3 manufacturers in China which they separately manufacture high quality video cables, patch cords and wire harness.	
                                            Based on the advantages below, after 5 years exploring, our products have been exporting to Europe and getting high reputation. </p>
                                        <div className="welcom-to-section-bottom d-flex justify-content-between">
                                            {/* <div className="welcom-btn-position"><NavLink to={"/about-1"} className="site-button-secondry site-btn-effect">More About Us</NavLink></div> */}
                                            {/* <div className="welcom-sign-pic"><img src={require('./../../images/sign.png').default} alt="" /></div>
                                            <div className="welcom-sign-info"><strong>Brayden</strong><span>( CEO &amp; Founder )</span></div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default About1;