import React from 'react';
import { NavLink } from 'react-router-dom';
import Switcher from '../Segments/Switcher';

var bgimage = require('./../../images/background/footer-bg.png');

class Footer extends React.Component {
    constructor(props) {
        super(props);

        var homepage4 = /\/home-transport/i;
        var homepage5 = /\/home-agriculture/i;

        if (homepage4.test(window.location.href)) {
            this.currentstate = { logo: require('./../../images/wellconwhite.png').default };
        } else if (homepage5.test(window.location.href)) {
            this.currentstate = { logo: require('./../../images/wellconwhite.png').default };
        } else {
            this.currentstate = { logo: require('./../../images/wellconwhite.png').default };
        }

    }

    updateFooterLogo = (updatedlogo) => { this.currentstate.logo = updatedlogo.default; }

    render() {

        return (
            <>
                <footer className="site-footer footer-large footer-dark text-white footer-style1">
                    {/* FOOTER BLOCKES START */}
                    <div className="footer-top bg-no-repeat bg-bottom-right" style={{ backgroundImage: 'url(' + bgimage.default + ')' }}>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-5 col-md-12 col-sm-12">
                                    <div className="footer-h-left">
                                        <div className="widget widget_about">
                                            <div className="logo-footer clearfix">
                                                <NavLink to={"./"}><img src={this.currentstate.logo} alt="Wellcon" style={{ width: 150 }} /></NavLink>
                                            </div>
                                            <p>Based on the advantages, after 5 years’ exploring, our products have been exporting to Europe and get high reputation. Furthermore, WELLCON is confidence to develop more marked across the world in the coming years.</p>
                                        </div>
                                        <div className="widget recent-posts-entry">
                                            <ul className="widget_address">
                                                <li> <i className="fa fa-phone" />+61 0468 799 916</li>
                                                {/* <li> <i className="fa fa-phone" />+61 88333 0380</li> */}
                                                <li><i className="fa fa-map-marker" />13 West Terrace, Kensington Gardens, SA 5068, AUSTRALIA</li>
                                                <li><i className="fa fa-envelope" />wyy@wellcon-au.com</li>

                                            </ul>
                                        </div>

                                    </div>
                                </div>
                                <div className="col-lg-7 col-md-12 col-sm-12">
                                    <div className="row footer-h-right">
                                        <div className="col-lg-5 col-md-4">
                                            <div className="widget widget_services">
                                                <h3 className="widget-title">Useful links</h3>
                                                <ul>
                                                    <li><NavLink to={"./"}>HOME</NavLink></li>
                                                    <li><NavLink to={"./"}>ABOUT</NavLink></li>
                                                    <li><NavLink to={"./"}>PRODUCT</NavLink></li>
                                                    <li><NavLink to={"./"}>CONTACT</NavLink></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-lg-7 col-md-8">
                                            <div className="widget widget_services">
                                                <h3 className="widget-title">Our Services</h3>
                                                <ul>
                                                    
                                                    <li><NavLink to={"./"}>Product development</NavLink></li>
                                                    {/* <li><NavLink to={"./"}>Maintenance</NavLink></li> */}
                                                    <li><NavLink to={"./"}>OEM</NavLink></li>
                                                    <li><NavLink to={"./"}>ODM</NavLink></li>
                                                    <li><NavLink to={"./"}>Technical Support</NavLink></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="widget widget_newsletter">
                                        <h3 className="widget-title">Newsletter</h3>
                                        <p>Subscribe to our newsletter to receive latest news on our services.</p>
                                        <div className="newsletter-input">
                                            <div className="input-group">
                                                <input id="email" type="text" className="form-control" name="email" placeholder="Enter your email" />
                                                <div className="input-group-append">
                                                    <button type="button" className="input-group-text nl-search-btn text-black site-bg-primary title-style-2">Subscribe</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* FOOTER COPYRIGHT */}
                    <div className="footer-bottom">
                        <div className="container">
                            <div className="wt-footer-bot-left d-flex justify-content-between">
                                <span className="copyrights-text">Copyright © 2023 <span className="site-text-primary">Wellcon Australia Pty Ltd</span></span>
                                <ul className="copyrights-nav">
                                    <li><NavLink to={"#"}>Terms  &amp; Condition</NavLink></li>
                                    {/* <li><NavLink to={"#"}>Privacy Policy</NavLink></li>
                                    <li><NavLink to={"/contactus"}>Contact Us</NavLink></li> */}
                                </ul>
                            </div>
                        </div>
                    </div>
                </footer>
                <Switcher updateFooterLogo={this.updateFooterLogo.bind(this)} />

            </>
        );
    };
};

export default Footer;